import axios from 'axios'

const apiURL = process.env.REACT_APP_API_URL

async function criteria(
  endpoint,
  token,
) {
  const config = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return window.fetch(`${apiURL}/api/${endpoint}`, config).then(async response => {
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

async function search(
  token,
  query
) {
  const config = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return window.fetch(`${apiURL}/api/search?q=${query}`, config).then(async response => {
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}

async function show(
  token,
  criteriaId
) {
  const config = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  return axios(`${apiURL}/api/criteria/${criteriaId}`, config).then(async response => {
    const data = response.data
    if (response.status === 200) {
      return data
    } else {
      throw new Error('401 Unauthorized.');
    }
  })
}

export {criteria, search, show}
