import React from 'react';
import styled from 'styled-components';

const Header = ({ children }) => {
  return (
    <StyledHeader>
      {children}
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props => props.theme.colors.purple};
`

export default Header;