import React from 'react';

const Glass = () => {
  return (
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 512 512" width='22px' height='22px'>
    <g>
        <g>
            <path fill='#691C75' d="M356.475,304.386c20.888-31.028,33.092-68.363,33.092-108.497c0-107.404-87.38-194.784-194.784-194.784
                S0,88.485,0,195.888s87.38,194.784,194.784,194.784c40.926,0,78.935-12.698,110.319-34.345l155.242,154.568L512,459.24
                L356.475,304.386z M194.784,335.963c-77.067,0-139.768-62.7-139.768-139.768c0-77.067,62.7-139.767,139.768-139.767
                s139.768,62.7,139.768,139.768S271.851,335.963,194.784,335.963z"/>
        </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    </svg>
    
  )
}
export default Glass;