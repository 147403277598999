import React from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

const ListItem = ({children, criteriaId}) => {
  return (
    <Anchor
      to={`/search/${criteriaId}`}
    >
    <Item>
      {children}
    </Item>
    </Anchor>
  )
}

const Item = styled.li`
  display: flex;
  justify-content: start;
  align-items: center;

  border-bottom: 1px solid ${props => props.theme.colors.gray};
  background: transparent;

  text-align: left;
  list-style-type: none;

  margin: 1rem;
  padding: 0.5rem;

  cursor: pointer;
`

const Anchor = styled(Link)`
  text-decoration: unset;
  all: unset;
`

export default ListItem