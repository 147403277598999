import React from 'react';
import styled from 'styled-components';

import Sidebar from '../../components/Sidebar'

const Burger = ({ user, toggle, onClick, logout }) => {
  return (
    <>
      <StyledBurger onClick={onClick}>
        <div />
        <div />
        <div />
      </StyledBurger>

    {toggle &&
      <Sidebar toggle={toggle} user={user} logout={logout} />
    }
    </>
  )
}

export default Burger;

const StyledBurger = styled.button`
  position: absolute;
  left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 2rem;
    height: 0.25rem;
    background: ${props => props.theme.colors.white};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`;