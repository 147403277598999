import React from 'react';
import styled from 'styled-components';

const Button = ({children, onClick}) => {
  return (
    <StyledButton
      onClick={onClick}
    >
        {children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  width: 80%;

  padding: 0.75rem 0;
  margin: 0.5rem 0;



  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple};

  font-size: ${props => props.theme.fontSizes.base};
  text-align: center;

  border: 1px solid ${props => props.theme.colors.white};

  cursor: pointer;
`

export default Button;