import React, { useState } from 'react';
import styled from 'styled-components';

import Burger from '../../components/Burger'
import Header from '../../components/Header'

import Search from '../../components/Search'

const Home = ({ user, logout }) => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false)

  const closeSidebar = () => {
    isSidebarOpened && setIsSidebarOpened(false)
  }

  return (
    <>
      <Header>
        <Burger
          user={user ? user : null}
          logout={logout}
          onClick={() => setIsSidebarOpened(!isSidebarOpened)}
          toggle={isSidebarOpened}
        />
        <Title>RedApp Garrahan</Title>
      </Header>
      <Search 
        onClick={() => closeSidebar()}
      />
    </>
  )
}

const Title = styled.h1`
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.medium};
`

export default Home;