const theme = {
    fontSizes: {
      xxs: '9px',
      xs: '12px',
      sm: '14px',
      base: '16px',
      medium: '24px',
      lg: '32px',
      xlg: '40px',
      xl: '48px',
      xxl: '64px'
    },
    texts: {
      dark: '#333'
    },
    colors: {
      primary: '#0092dd',
      dark: '#333',
      gray: '#BAC0CB',
      lightGray: '#E8E8E8',
      error: '#ed4337',
      green: '#00DD88',
      white: '#fff',
      purple: '#691C75'
    },
    breakpoints: {
      xs: '480px',
      sm: '768px',
      md: '1024px',
      lg: '1311px',
    }
  }
    
    export default theme