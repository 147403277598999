import React, { useState, useEffect } from 'react';
import styled from 'styled-components'

import {getToken} from '../../utils/auth'
import {criteria} from '../../utils/api-client'
import {useAsync} from '../../hooks/use-async'
import {search} from '../../utils/api-client'

import SearchInput from '../../components/SearchInput'
import ListItem from '../../components/ListItem'
import Spinner from '../../assets/svgs/Spinner'

import fgLogo from '../../assets/images/fg-logo.png'

import {ErrorBoundary} from 'react-error-boundary'

function ErrorFallback({}) {
  const localStorageKey = '__auth_provider_token__'

  const handleLogout = () => {
    window.localStorage.removeItem(localStorageKey)
    window.location.reload()
  }
  return (
    <div style={{background: '#691C75', color: 'white', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <p>Tu sesión ha expirado:</p>
      <button onClick={() => handleLogout()} style={{background: 'transparent', color: 'white', border: 'none'}}>Haz click aquí para ingresar nuevamente</button>
    </div>
  )
}

const Search = ({ onClick }) => {
  const [query, setQuery] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const {data, isError, run, setData} = useAsync()
  const {data: items, setData: setItems, run: runSearch, isError: isSearchError} = useAsync()
  const token = getToken()

  const searchByQuery = e => {
    setQuery(e.target.value)
  }

  useEffect(() => {
    run(criteria('criteria', token)).then(res => setData(res))
  }, [run, token, setData])

  const dataCopy = data && [...data]
  const dictionary = dataCopy?.reduce((acc, el) => {
    acc[el.id] = el
    return acc
  }, {})

  const itemsCopy = items && [...items]
  const criteriaResults = itemsCopy?.map(li => dictionary[li])

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if(query.length < 2) return
      setIsLoading(true)
      runSearch(search(token, query)).then(res => setItems(res))
      setIsLoading(false)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [runSearch, query, token])
  
  if(isSearchError) throw new Error('401 Unauthorized.')

  return (
    <Main onClick={onClick}>
      <Image src={fgLogo} width='240' height='70' alt='Fundación garrahan' />
      <SearchInput
        type='text'
        name='query'
        onChange={(e) => searchByQuery(e)}
      />

      {isLoading 
        ? <Spinner style={{marginTop: '4rem'}} fill='black' />
        : <ul style={{margin: '0', padding: '0', width: '100%'}}>
            {criteriaResults?.map(listItem => (
              <ListItem 
                key={listItem.id}
                criteriaId={listItem.id}
              >
                {listItem.title}
              </ListItem>
            ))}
          </ul>
        }
    </Main>
  )
}

const Main = styled.main`
  min-height: 80vh; 
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Image = styled.img`
  margin: 2rem 0;
`

export default Search;