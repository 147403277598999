import React from 'react';
import styled from 'styled-components';

import Spinner from '../../assets/svgs/Spinner'

const FullPageSpinner = () => {
  return (
    <StyledSpinner
      
    >
        <Spinner fill='white' width='60px' height='60px' />
    </StyledSpinner>
  )
}

const StyledSpinner = styled.div`
  background: #691C75;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export default FullPageSpinner;