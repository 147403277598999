import React from 'react';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';

import Button from '../../components/Button'

const TermsAndConditions = () => {
  let history = useHistory();
  return (
    <Wrapper>
      <Terms>Términos y condiciones</Terms>
      <TextWrapper>
        <Title>Sobre el proyecto</Title>

        <Paragraph>Leer antes de comenzar a tomar decisiones basadas en la aplicación.</Paragraph>

        <Paragraph>Antes de cada donación, los donantes sangre son evaluados para identificar si la donación de sangre puede implicar un riesgo para su salud o la salud de los pacientes que recibirán una transfusión.</Paragraph>

        <Paragraph>Se utiliza para ello una entrevista personal que permite identificar aquellos factores de riesgo y a través de la cual los donantes son diferidos o aceptados para donar sangre en un determinado momento.</Paragraph>

        <Paragraph>El contenido de cada una de las recomendaciones está basado en las normas y regulaciones
        vigentes en Argentina y en un proceso de revisión riguroso y no sistemático de los datos epidemiológicos locales y la evidencia científica disponible.</Paragraph>

        <Paragraph>En relación a comportamientos de riesgo y condiciones médicas del donante la evidencia de
        alta calidad metodológica es en muchos casos limitada y en ocasiones inexistente. Ante la ausencia de evidencia publicada las recomendaciones se basan en las mejores prácticas internacionales y el conocimiento de los campos de la fisiología humana, patología y medicina clínica.</Paragraph>

        <Paragraph>En condiciones en las que el conocimiento actual podría sugerir criterios de diferimiento más laxos, se recomienda un enfoque de precaución, priorizando la seguridad del paciente y del donante hasta que surja nueva evidencia más robusta.</Paragraph>

        <Paragraph>Está dirigida a los profesionales que ya se encuentren capacitados en la selección de los
        donantes de sangre, para asistirlos en el proceso de toma de decisiones respecto a la aceptación o diferimiento de los donantes de sangre, pero de ningún modo reemplaza el juicio clínico del entrevistador y la evaluación integral del potencial donante.</Paragraph>

        <Paragraph>El desarrollo de esta plataforma surge de la necesidad de contar con una herramienta ágil, de consulta en el sitio de atención de los donantes, que permita ser actualizada a intervalos regulares, según la evidencia científica disponible y los cambios epidemiológicos locales.</Paragraph>

        <Paragraph>Asimismo, permitirá disminuir la variabilidad entre entrevistadores y aumentar la precisión
        del instrumento, asegurando que no se difieren donantes innecesariamente, o por el contrario, que se les aconseja no donar si tienen algún factor de riesgo que se lo impida.</Paragraph>

        <Paragraph>Los Bancos de Sangre tienen la responsabilidad de proteger a los donantes y a los receptores de la sangre y sus componentes, y el uso apropiado de esta plataforma contribuirá al logro de ese objetivo.</Paragraph>

        <Paragraph>Silvina Kuperman</Paragraph>

        <Paragraph>Jefa del Centro Regional de Hemoterapia Hospital de Pediatria Garrahan Buenos Aires, Argentina</Paragraph>

        <Title>Términos &amp Condiciones</Title>
        <Paragraph>Este documento describe los Términos y Condiciones que aceptan los usuarios antes de comenzar a utilizar la Aplicación.</Paragraph>

        <Title>Aceptación de Términos y Condiciones</Title>
        <Paragraph>El acceso y/o uso de esta Aplicación (en adelante la "Aplicación") implica que, a partir de dicho momento, sus Usuarios adhieren y acepten la totalidad de los Términos y Condiciones que se establecen a continuación, y aceptando también las Condiciones Particulares que en el futuro puedan complementarlas, sustituirlas y/o modificarlas en relación con los servicios y contenidos de la Aplicación. Por el sólo hecho de ingresar y hacer uso del mismo sitio, los Usuarios adhieren en forma automática e inmediata a todos y a cada uno de los siguientes Términos y Condiciones. Por favor, lea detenidamente estos Términos y Condiciones antes de acceder y/o utilizar cualquier servicio de la Aplicación bajo su entera responsabilidad.</Paragraph>

        <Title>Privacidad y Seguridad de Datos Personales</Title>

        <Paragraph>Fundación Garrahan se compromete a proteger la privacidad de todos los Usuarios de la Aplicación por sí y por la entidad Hospital Garrahan, beneficiaria asimismo y usuaria para la citada aplicación. Fundación Garrahan no vende, ni alquila ni canjea ni ofrece de ninguna forma la información personal de los Usuarios. Fundación Garrahan utiliza la información cargada por los Usuarios o solicitada a los mismos,y la información ingresada de los usuarios por los Administradores de la Aplicación, para facilitar, optimizar y potenciar la experiencia de los Usuarios en el uso de la Aplicación.</Paragraph>
      </TextWrapper>
      <Button onClick={() => history.push('/home')}>
        Aceptar
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;

  padding: 2rem 0;

  background: ${props => props.theme.colors.purple};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const TextWrapper = styled.div`
  margin: 1rem 2rem;

  padding: 1rem 1.25rem;

  background: ${props => props.theme.colors.white};

  border: none;
  border-radius: 8px;
`

const Terms = styled.h1`
  margin: 2rem 0 0.5rem 0;
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: normal;

  color: ${props => props.theme.colors.white};
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
`

const Paragraph = styled.h3`
  font-size: ${props => props.theme.fontSizes.base};
  font-weight: normal;
`


export default TermsAndConditions;