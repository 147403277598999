import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from "react-router-dom";

import Login from '../../scenes/Login'
// const Register = React.lazy(() => import('../../scenes/Register'));
import Register from '../../scenes/Register'

const UnauthenticatedRoutes = ({ user, login, register, error }) => {
  console.log(error);
  return (
    <Switch>
      <Route path="/login"><Login user={user} login={login} /></Route>
      <Route path="/register"><Register register={register} /></Route>
      {/* <Route path="/restore" element={<FinishedScreen />} /> */}
    </Switch>
  )
}

const Unauthenticated = (props) => {
  return (
    <Wrapper>
      <UnauthenticatedRoutes {...props} />
    </Wrapper>
  )
}



const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  background: ${props => props.theme.colors.purple};
`

export default Unauthenticated;