import React from 'react';
import styled from 'styled-components';

import fibertel from '../../assets/images/fibertel-logo.png'
import wingu from '../../assets/images/wingu-logo.png'
import garrahan from '../../assets/images/fg-logo.png'

const About = () => {
  return (
    <Wrapper>
      <Title>Acerca del proyecto</Title>    
      <Description>Antes de cada donación, los donantes sangre son evaluados para identificar si la donación de sangre puede implicar un riesgo para su salud o la salud de los pacientes que recibirán una transfusión.</Description>
      <Description>Se utiliza para ello una entrevista personal que permite identificar aquellos factores de riesgo y a través de la cual los donantes son diferidos o aceptados para donar sangre en un determinado momento.</Description>
      <Description>El contenido de cada una de las recomendaciones está basado en las normas y regulaciones vigentes en Argentina y en un proceso de revisión riguroso y no sistemático de los datos epidemiológicos locales y la evidencia científica disponible.</Description>
      <Description>En relación a comportamientos de riesgo y condiciones médicas del donante la evidencia de alta calidad metodológica es en muchos casos limitada y en ocasiones inexistente. Ante la ausencia de evidencia publicada las recomendaciones se basan en las mejores prácticas internacionales y el conocimiento de los campos de la fisiología humana, patología y medicina clínica.</Description>
      <Description>En condiciones en las que el conocimiento actual podría sugerir criterios de diferimiento más laxos, se recomienda un enfoque de precaución, priorizando la seguridad del paciente y del donante hasta que surja nueva evidencia más robusta.</Description>
      <Description>Está dirigida a los profesionales que ya se encuentren capacitados en la selección de los donantes de sangre, para asistirlos en el proceso de toma de decisiones respecto a la aceptación o diferimiento de los donantes de sangre, pero de ningún modo reemplaza el juicio clínico del entrevistador  y la evaluación integral del potencial donante.</Description>
      <Description>El desarrollo de esta plataforma surge de la necesidad de contar con una herramienta ágil, de consulta en el sitio de atención de los donantes, que permita ser actualizada a intervalos regulares, según la evidencia científica disponible y los cambios epidemiológicos locales.</Description>
      <Description>Asimismo, permitirá disminuir la variabilidad entre entrevistadores y aumentar la precisión del instrumento, asegurando que no se difieren donantes innecesariamente, o por el contrario, que se les aconseja no donar si tienen algún factor de riesgo que se lo impida.</Description>
      <Description>Los Bancos de Sangre tienen la responsabilidad de proteger a los donantes y a los receptores de la sangre y sus componentes,  y el uso apropiado de esta plataforma contribuirá al logro de ese objetivo.</Description>

      <Legend>
        Silvina Kuperman
      </Legend>
      <Legend>
        Jefa del Centro Regional de Hemoterapia
      </Legend>
      <Legend>
        Hospital de Pediatria Garrahan
      </Legend>
      <Legend>
        Buenos Aires, Argentina
      </Legend>

      <Description>Este proyecto se llevo a cabo con el apoyo de:</Description>
      <Image src={garrahan} alt='Fundación garrahan' />
      <Image src={fibertel} style={{height: '100px'}} alt='Fibertel' />
      <Image src={wingu} alt='Wingu'  />

    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 2rem;
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: 600;

  margin-bottom: 1rem;
`

const Description = styled.p`
  font-size: ${props => props.theme.fontSizes.medium};
  letter-spacing: 1px;
  color: #808080;
  font-weight: normal;

  margin-bottom: 1rem;
`

const Legend = styled.legend`
  font-size: ${props => props.theme.fontSizes.sm};
  font-weight: 600;

  margin: 0.5rem 0;
`

const Image = styled.img`
  width: 233px;
  height: 75px;

  margin: 1rem 0;
`

export default About;