import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom'

import { useAsync } from '../../hooks/use-async'

import Input from '../../components/Input'
import Button from '../../components/Button'

import logo from '../../assets/images/app_logo.png'

const Register = ({ register }) => {
  const {isLoading, isError, isSuccess, run} = useAsync()
  function handleSubmit(event) {
    event.preventDefault()
    const {name, email, password, password_confirmation} = event.target.elements

    if(password.value !== password_confirmation.value) throw new Error('Credentials error') 

    run(
      register(
        'register',
        {
          name: name.value,
          email: email.value,
          password: password.value,
          password_confirmation: password_confirmation.value,
        }
      ),
    )
  }
  return (
    <Wrapper>
      <Logo src={logo} width="150" height="110" alt='Red App' />
      <Title>Crear cuenta</Title>

      <Form 
        onSubmit={handleSubmit}
      >
        <Input 
          placeholder='Nombre y apellido'
          type='text'
          name='name'
          required
        />
        <Input 
          placeholder='Email'
          type='email'
          name='email'
          required
        />

        <Input 
          placeholder='Password'
          type='password'
          name='password'
          required
          minLength='6'
        />

        <Input 
          placeholder='Confirmar contraseña'
          type='password'
          name='password_confirmation'
          required
          minLength='6'
        />

        <Button type='submit'>
          {isLoading ? 'Creando cuenta...' : 'Crear cuenta'}
        </Button>

        {isError ?
          <pre style={{color: 'white'}}>Oops. Algo no salió como esperábamos.</pre>
         : null}

        {isSuccess ?
          <pre style={{color: 'white'}}>Usuario creado correctamente y pendiente de aprobación.</pre>
         : null}
      </Form>

      <AlreadyHaveAccWrapper>
        <p>¿Ya tienes cuenta?</p>
        <StyledLink
          to='/login'
        >
          Login
        </StyledLink> 
      </AlreadyHaveAccWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  background: ${props => props.theme.colors.purple};
`

const Logo = styled.img``

const Title = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: ${props => props.theme.colors.white};
`

const AlreadyHaveAccWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;

  color: ${props => props.theme.colors.white};
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: 100%;
`

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;

  margin: 0.5rem;
  color: ${props => props.theme.colors.white};
`

export default Register;