import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

import logo from '../../assets/images/app_logo.png';
import { useAsync } from '../../hooks/use-async'

import Input from '../../components/Input'
import Button from '../../components/Button'

import Spinner from '../../assets/svgs/Spinner'

const errors = {
  'Unauthorized': 'Usuario o contraseña incorrectos'
}

const Login = ({ login }) => {
  const {isLoading, run, error} = useAsync()

  const handleSubmit = (event) => {
    event.preventDefault()
    const {email, password} = event.target.elements

    run(
      login(
        'login',
        {
          email: email.value,
          password: password.value,
        }
      ),
    )
  }
  
  return (
    <Wrapper>
      <Logo src={logo} width="150" height="110" alt='RedApp' />
      <Title>RedApp</Title>

      <Form 
        onSubmit={handleSubmit}
      >
        <Input 
          placeholder='Email'
          type='text'
          name='email'
        />
        <Input 
          placeholder='Password'
          type='password'
          name='password'
        />
        <Button type='submit'>
          {isLoading ? <Spinner fill='white' /> : 'Login'}
        </Button>

        {error ? <pre style={{color: 'white'}}>{errors[error.error]}</pre> : null}
      </Form>

      <RegisterWrapper>
        <p>No tienes cuenta?</p>
        <StyledLink
          to='/register'
        >
          Regístrate
        </StyledLink> 
      </RegisterWrapper>

      <Anchor
        href='https://bancodesangre.fhg.org.ar/password/reset'
        target='_blank'
        rel='noopener'
      >
        Restablecer contraseña
      </Anchor>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  background: ${props => props.theme.colors.purple};
`

const Logo = styled.img``

const Title = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: ${props => props.theme.colors.white};
`

const RegisterWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.white};
`

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;

  margin: 0.5rem;
  color: ${props => props.theme.colors.white};
`

const Anchor = styled.a`
  cursor: pointer;
  text-decoration: underline;

  margin: 0.5rem;
  color: ${props => props.theme.colors.white};
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: 100%;
`

export default Login;