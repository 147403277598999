import React from 'react'
import { Switch, Route } from "react-router-dom"

import Home from '../../scenes/Home'

// const Terms = React.lazy(() => import('../../scenes/Terms'));
// const About = React.lazy(() => import('../../scenes/About'));
// const CriteriaItem = React.lazy(() => import('../../components/CriteriaItem'));

import Terms from '../../scenes/Terms'
import About from '../../scenes/About'
import CriteriaItem from '../../components/CriteriaItem'

const AuthenticatedRoutes = ({ user, logout }) => {
  return (
    <Switch>
      <Route path="/terms"><Terms /></Route>
      <Route path="/home"><Home user={user} logout={logout} /></Route>
      <Route path="/search/:criteriaId"><CriteriaItem /></Route>
      <Route path="/about"><About /></Route>
    </Switch>
  )
}

const Authenticated = (props) => {
  return (
    <>
      <AuthenticatedRoutes {...props} />
    </>
  )
}

export default Authenticated;