import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Sidebar = ({ user, logout }) => {
  let history = useHistory();

  console.log(user, 'user')

  return (
    <Wrapper>
      <Header>
        <Username>
          {user?.name}
        </Username>
        <Email>
          {user?.email}
        </Email>
      </Header>

      <Items>
        <Item href='https://bancodesangre.fhg.org.ar/terminos-y-condiciones' target='_blank' rel='noopener'>
          Términos y condiciones
        </Item>
        <Item onClick={() => history.push('/about')}>
          Sobre el proyecto
        </Item>
        <Item onClick={() => logout('logout', user.token)}>
          Cerrar sesión
        </Item>
      </Items>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};

  height: 100vh;
  max-height: 100vh;
  
  width: 70vw;

  position: absolute;
  top: 0;
  left: 0;  

  z-index: 1;
`

const Header = styled.div`
  height: 10vh;
  
  padding: 2rem 1rem;

  background: ${props => props.theme.colors.purple};
`

const Username = styled.p`
  color: ${props => props.theme.colors.white};

  font-size: ${props => props.theme.fontSizes.base};
  font-weight: normal;

  margin: 0;
`

const Email = styled.p`
  color: ${props => props.theme.colors.white};

  font-size: ${props => props.theme.fontSizes.base};
  font-weight: normal;

  margin: 1rem 0;
`

const Items = styled.div`
  display: flex;
  flex-direction: column;

  margin: 1rem;
`

const Item = styled.a`
  color: ${props => props.theme.colors.dark};

  margin: 1.5rem 0;

  text-decoration: none;

  &:last-child {
    border-top: 1px solid ${props => props.theme.colors.gray};
    padding-top: 2rem;
  }
`

export default Sidebar;