import React from 'react';
import styled from 'styled-components';

import GlassIcon from '../../assets/svgs/Glass'

const SearchInput = ({onChange, name, type}) => {
  return (
    <Wrapper>
      <StyledSearchInput 
        type={type}
        onChange={onChange}  
        name={name}
        placeholder='Buscar por condición del donante.'
        />
      <IconWrapper>
        <GlassIcon />
      </IconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 80%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
`

const StyledSearchInput = styled.input`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.75rem 0.5rem;

  border: 1px solid ${props => props.theme.colors.purple};

  font-size: ${props => props.theme.fontSizes.base};
`

const IconWrapper = styled.div`
  position: absolute;
  right: 0;

  padding-right: 50px;
`

export default SearchInput;