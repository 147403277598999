import React from 'react';
import styled from 'styled-components';

const Input = ({type, ...props}) => {
  return (
    <StyledInput
      type={type}
      {...props}
    />
  )
}

const StyledInput = styled.input`
  width: 80%;

  padding: 0.75rem 0;
  margin: 0.5rem 0;

  font-size: ${props => props.theme.fontSizes.base};
  text-align: center;

  border: none;
`

export default Input;