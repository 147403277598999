import React, { useEffect } from 'react'
import * as auth from './utils/auth'
import {Redirect, BrowserRouter as Router} from 'react-router-dom'
import {useAsync} from './hooks/use-async'
import {getToken} from './utils/auth'
import { ThemeProvider } from 'styled-components'
import theme from './utils/theme'
import Unauthenticated from './scenes/Unauthenticated'
import Authenticated from './scenes/Authenticated'
import FullPageSpinner from './components/FullPageSpinner'
import {ErrorBoundary} from 'react-error-boundary'

function ErrorFallback({}) {
  const localStorageKey = '__auth_provider_token__'

  const handleLogout = () => {
    window.localStorage.removeItem(localStorageKey)
    window.location.reload()
  }
  return (
    <div style={{background: '#691C75', color: 'white', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <p>Tu sesión ha expirado:</p>
      <button onClick={() => handleLogout()} style={{background: 'transparent', color: 'white', border: 'none'}}>Haz click aquí para ingresar nuevamente</button>
    </div>
  )
}

function App() {
  const {
    data: user,
    run,
    isLoading,
    setData,
  } = useAsync()

  const token = getToken()

  const me = async () => {
    let user = null
    const token = getToken()
  
    if(!token) return
  
    await auth.me('me', token).then(userData => user = userData)

    return user
  }

  useEffect(() => {
    run(me())
  }, [run])


  const login = (ep, form) => auth.login(ep, form).then(user => setData(user))
  const register = (ep, form) => auth.register(ep, form).then(res => res)
  const logout = (ep, token) => {
    auth.logout(ep, token)
    setData(null)
  }

  if(isLoading) return <FullPageSpinner />

  const props = {user, login, register, logout}
  return token ? (
      <ThemeProvider theme={theme}>
        <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Authenticated {...props} />
          <Redirect from="/login" to="/terms" />
        </ErrorBoundary>
        </Router>
      </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <Router>
        <Unauthenticated {...props} />
        <Redirect from="/" to="/login" />
      </Router>
    </ThemeProvider>
  )
}


export {App}
