
const localStorageKey = '__auth_provider_token__'

const handleUserResponse = (data) =>  {
  window.localStorage.setItem(localStorageKey, data.token)
  return data
}

export const getToken = () => {
  return window.localStorage.getItem(localStorageKey)
}

export const login = async (ep, {email, password}) => {
  return client(ep, {email, password}).then(handleUserResponse)
}

export const me = async (ep, token) => {
  return client(ep, null, token).then(res => res)
}

export const register = async (ep, {name, email, password, password_confirmation}) =>  {
  return client(ep, {name, email, password, password_confirmation}).then(res => res)
}

export const logout = async (ep, token) => {
  window.localStorage.removeItem(localStorageKey)
  return client(ep, null, token).then(async res => {
    return res
  })
}

const authURL = process.env.REACT_APP_API_URL
const client = async(endpoint, data, token) => {
  const config = {
    method: 'POST',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : undefined,
    },
  }

  return window.fetch(`${authURL}/api/auth/${endpoint}`, config).then(async response => {
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}
