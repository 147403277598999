import React, { useEffect } from 'react'
import styled from 'styled-components'
import {useParams, Link} from 'react-router-dom'
import {show} from '../../utils/api-client'
import { useAsync } from '../../hooks/use-async'
import { getToken } from '../../utils/auth'
import Spinner from '../../assets/svgs/Spinner'

const CriteriaItem = () => {
  const { criteriaId } = useParams()
  const {data, isLoading, isError, run, setData} = useAsync()
  const token = getToken()

  useEffect(() => {
    run(show(token, criteriaId)).then(res => setData(res))
  }, [run, criteriaId, setData, token])

  // if has any related restriction it has to be priorized. if not, let's show the first restriction.
  const restrictions = data?.restriction ? data?.restriction : (data?.relateds.length > 0 && data?.relateds[0].restriction) ? data?.relateds[0].restriction : null

  // same as restrictions.
  const additional_information = data?.additional_information ? data?.additional_information : (data?.relateds.length > 0 && data?.relateds[0].additional_information) ? data?.relateds[0].additional_information : 'Sin información adicional'

  const acceptance = data?.acceptance ? data?.acceptance : (data?.relateds.length > 0 && data?.relateds[0].acceptance) ? data?.relateds[0].acceptance : null


  return (
    <>
      <h1 style={{fontSize: '24px', fontWeight: '500', margin: '1rem', color: '#691C75'}}>{data?.title}</h1>
        {
          restrictions 
            ? <>
                <Title>Restricción</Title>
                {isLoading ? <Spinner /> : <Text>{restrictions}</Text>}
              </>
            : null
        }
          
        {
          acceptance 
            ? <>
                <Title>Aceptación</Title>
                {isLoading ? <Spinner /> : <Text>{acceptance}</Text>}
              </>
            : null
        }

      <Title>Información adicional</Title>
      {isLoading ? <Spinner /> : <Text>{additional_information}</Text>}

        <h5 style={{paddingLeft: '1rem'}}>Última actualización del criterio:</h5>
        <Date>{data?.modification}</Date>

        {data?.relateds.length > 0 ? <Title>Relacionados</Title> : null}

        {data?.relateds.length > 0 
          ? data?.relateds.map(r => (
              <LinkWrapper key={r?.id}>
                <CustomLink 
                  key={r.id}
                  to={`/search/${r.id}`}
                >
                <p style={{width: 'fit-content', color: 'white', background: '#691C75', padding: '0.5rem'}}>{r.title}</p>
                </CustomLink>
              </LinkWrapper>
            )) 
        : null}
    </>
  )
}

const Title = styled.h1`
  background: ${props => props.theme.colors.lightGray};
  font-size: ${props => props.theme.fontSizes.base};
  font-weight: unset;
  text-transform: uppercase;
  color: ${props => props.theme.colors.purple};

  padding: 1rem;
`
const Text = styled.p`
  padding: 1rem;
`

const Date = styled.p`
  padding-left: 1rem;
`

const LinkWrapper = styled.div`
  margin: 1rem 0.5rem;
`

const CustomLink = styled(Link)`
  background: ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.white};
  
  text-decoration: unset;
`

export default CriteriaItem;